import Swup from 'swup'
import SwupGaPlugin from '@swup/ga-plugin'
import SwupProgressPlugin from '@swup/progress-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import * as basicLightbox from 'basiclightbox'
import $ from 'jquery'
import 'lazysizes'

window.lazySizesConfig = {
	addClasses: true
};

// --------------------

const linkInclude = [
  `a[href^="${window.location.origin}"]`,
  `a[href^="/"]`,
  `a[href^="#"]`
]

const linkExclude = [
  `[download]`,
  `[target="_blank"]`,
  `[data-no-swup]`,
  `[href$=".pdf"]`
]

const exclude = linkExclude.map(selector => `:not(${selector})`).join('')
const linkSelector = linkInclude.map(include => `${include}${exclude}`).join(',')

const options = {
  cache: true,
  linkSelector: linkSelector,
  plugins: [
    new SwupProgressPlugin({
      className: 'swup-progress-bar',
      transition: 360,
      delay: 0
    }),
    new SwupGaPlugin({
      gaMeasurementId: 'G-D3FKX35C29',
    }),
    new SwupScrollPlugin()
  ],
}
// const swup = new Swup(options)

// --------------------

function variablesUpdate() {
  console.log('variablesUpdate')
  var isTouch = 'ontouchstart' in window || (navigator.msMaxTouchPoints > 0) || $('html').hasClass('touch')
  var vw = $(window).innerWidth()
  var vh = $(window).innerHeight()

  $('body').css({
    '--touch': isTouch,
    '--window-width': vw + 'px',
    '--window-height': vh + 'px',
  })
}

// --------------------

function init() {
  console.log('init')
  variablesUpdate()

  // Lighbox
  $('[data-lightbox]').each(function(index, element) {
    $(element).on('click', function(e) {
      e.preventDefault()
      basicLightbox.create(`<img src="${$(element).attr('href')}">`).show()
    })
  })
}

// --------------------

var resizeTimer = false
$(window).on('resize', function(e) {
  if (!resizeTimer) {
    $(window).trigger('resizestart')
  }

  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function() {
    resizeTimer = false
    $(window).trigger('resizeend')
  }, 360)

}).on('resizestart', function(){
}).on('resizeend', function() {
  variablesUpdate()
})

// --------------------

$(window).on('load', function(){
  init()
})

// --------------------

swup.on('contentReplaced', function () {
  init()
})